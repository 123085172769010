/* App Colors */
.grey-background {
  background-color: #c5c1c0;
}
/* Login Page */
.login {
  min-height: 100vh;
}

.login-bg {
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}

.alert-message-danger {
  background-color: #ff5857;
}

/* Listing Row */
.listing-row:last-child {
  border-bottom: none !important;
}

/* Letter Spaceing */
.ls-1 {
  letter-spacing: 0.1em;
}

/* Status Styling */
.submitted {
  background-color: #aee8eb;
  color: #1a6f72;
}

.rejected {
  background-color: #ffe69b;
  color: #9d7600;
}

.approved {
  background-color: #e7f5ea;
  color: #677e6c;
}

/* Sort & Filter Dropdowns */
.dropdown-icon::after {
  display: none !important;
}

.dropdown-icon:focus {
  outline: 0;
  box-shadow: none;
}

.dropdown-menu-list {
  box-shadow: 0 0 50px 20px rgb(82 63 105 / 10%);
  border: none;
}

.dropdown-menu-list .dropdown-item:focus,
.dropdown-menu-list .dropdown-item:hover {
  border-radius: 6px;
}

#sort-dropdown {
  overflow-y: auto;
}

@media (min-width: 575px) {
  /* Sidebar */
  .layout-nav-side {
    display: flex;
  }
  .layout-nav-side > #sidebar {
    width: 220px;
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    flex-direction: column;
    align-items: flex-start;
  }
  .layout-nav-side > #sidebar div,
  #log-out-btn {
    width: 100%;
  }

  .layout-nav-side #main-content.container-fluid {
    flex: 1;
    max-width: calc(100vw - 200px);
  }

  /* Search Form in Home Header */
  #search-form {
    flex: 1;
    width: 380px;
    max-width: calc(100vw - 200px);
  }
}
